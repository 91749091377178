class searchFrom {
    /* eslint no-underscore-dangle: 0 */
validateSort = (rule, value, callback) => {
        // const sort = value.replace(/\s/g, "");//去除空格
        //校验排序
        const reg = /^[1-9]\d*$/;
        if (!value) {
            console.log(!value);
            callback([new Error('排序不能为空')]);
        } else if (!reg.test(value)) {
            callback([new Error('排序输入不合法')]);
       } else {
           callback()
       }
}

  constructor(type) {
      if (type === 'form') {
        this.videoName = ''
        this.videoPictureUrl = ''
        this.sort = ''
        this.videoContentUrl = ''
      } else if (type === 'rule') {
        this.videoName = [
          {
            required: true,
            message: '视频名称不能为空',
            trigger: 'blur',
          },
        ]
        this.videoPictureUrl = [
            {
              required: true,
              message: '视频封面不能为空',
              trigger: ['blur', 'change'],
            },
          ]
        this.sort = [
            {
              required: true,
              validator: this.validateSort,
              trigger: ['blur', 'change'],
            },
          ]
        this.videoContentUrl = [
            {
              required: true,
              message: '视频上传不能为空',
              trigger: ['blur', 'change'],
            },
          ]
      }
    }
  }
  export default searchFrom
