<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? '编辑视频' : '上传视频'}`"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="50%"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="视频名称" prop="videoName" class="video-input">
              <span class="video-text">{{ form.videoName.length }}/45</span>
              <el-input v-model="form.videoName" maxlength="45"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="视频封面" prop="videoPictureUrl">
              <div class="suggested-text">
                建议尺寸700*450px或14：9，JPG、PNG格式，图片小于5M
              </div>
              <div>
                <el-upload
                  v-if="showPic"
                  class="upload"
                  :http-request="uploadFile"
                  :show-file-list="false"
                  :with-credentials="true"
                  :action="apis.ImageUpload"
                  :httpRequest="uploadHttpDefault"
                  :headers="{
                    token: TOKEN,
                  }"
                  name="file"
                  accept=".png,.jpg"
                  :limit="1"
                  :before-upload="beforeUpload"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                >
                  <div>
                    <img
                      v-if="form.videoPictureUrl"
                      :src="form.videoPictureUrl"
                      alt
                      srcset
                      width="130"
                      height="84"
                    />
                    <div class="upload-btn" slot="trigger">选择文件</div>
                  </div>
                </el-upload>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="显示顺序" prop="sort">
              <el-input
                clearable
                v-model="form.sort"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="视频上传" prop="videoContentUrl">
              <div class="suggested-text">
                格式支持MP4，大小不超过1G，建议视频分辨率1280*720
              </div>
              <div>
                <el-upload
                  class="upload"
                  :http-request="uploadFile"
                  :with-credentials="true"
                  :action="apis.ImageUpload"
                  :httpRequest="uploadHttpDefault"
                  :headers="{
                    token: TOKEN,
                  }"
                  name="file"
                  accept=".mp4"
                  :limit="1"
                  :file-list="fileList"

                  :on-change="handleChange"
                  :before-upload="beforeUploadVideo"
                  :on-success="handleVideoSuccess"
                  :on-error="uploadVideoError"
                  :on-remove="handleRemove"
                >
                  <div class="upload-btn" slot="trigger">选择文件</div>
                </el-upload>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <!-- <div class="footer-confirm" @click="onSave">{{ $t("sure") }}</div> -->
        <el-button class="footer-confirm" @click="sureEvent(1)">上架</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="sureEvent(0)">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";
import { Loading } from 'element-ui';

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  watch: {},
  mounted() {
    console.log("DROPDOWNBOX.VENUE_SERVICE", this.DROPDOWNBOX);
  },
  data() {
    return {
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      apis,
      fileList: [],
      showPic: true,
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueList", "venueId"]),
  },
  methods: {
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init();
      // this.$refs.bMap.clearData();
      this.$emit("closeDialog", false);
    },
    ...mapMutations(["setVenueId", "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.fileList = [];
      this.form = new AddDialogClass("form");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent(type) {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            const editForm = {
              id: this.form.id,
              publishStatus: type,
              sort: this.form.sort,
              videoContentUrl: this.form.videoContentUrl,
              videoName: this.form.videoName,
              videoPictureUrl: this.form.videoPictureUrl,
            };
            this.$http
              .put(apis.updateVenueVideo, { ...editForm })
              .then((res) => {
                if (res.data.code === 0) {
                  this.$message.success("编辑成功!");
                  this.closeDialog();
                }
              });
          } else {
            const addData = {
              ...this.form,
              publishStatus: type,
            };
            this.$http
              .post(apis.insertVenueVideo, { ...addData })
              .then((res) => {
                if (res.data.code === 0) {
                  this.$message.success("上传成功!");
                  this.closeDialog();
                }
              });
          }
        }
      });
    },
    getDataQuery(data) {
      this.form = Object.assign({}, data);
      console.log(data);
      this.fileList.push({ name: data.videoName, url: data.videoContentUrl });
    },
    uploadSuccess(res) {
      this.form.videoPictureUrl = res.data.url;
      this.showPic = false;
      this.$nextTick(() => {
        this.showPic = true;
      });
      this.$refs.form.validateField("videoPictureUrl");
    },
    // 图片格式校验
    beforeUpload(file) {
      // 格式校验
      const isFormat = file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg";
      // 校验图片大小
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.warning("上传图片大小不能超过5MB");
      }
      if (!isFormat) {
        this.$message.warning("上传的图片类型错误");
      }
    },
    uploadFile(file) {
      console.log(file);
    },
    handleChange(file, fileList) {
      console.log(fileList);
    },
    // 视频格式校验
    beforeUploadVideo(file) {
      console.log(file);
      // 校验图片大小
      const isLt1M = file.size / 1024 / 1024 < 1024;
      if (!isLt1M) {
        this.$message.warning("上传视频大小不能超过1G");
      } else {
        this.loadingInstance = Loading.service({
          fullscreen: true,
          text: '视频上传中，请等待',
          background: 'rgba(255,255,255,.5)',
        });
      }
      return !!isLt1M;
    },
    uploadError(res) {
      console.log(res);
    },
    uploadVideoError(err) {
      this.loadingInstance.close()
      this.$message.error('视频上传失败');
      console.log(err);
    },
    handleVideoSuccess(res) {
      this.loadingInstance.close()
      this.$message({
        message: '视频上传成功',
        type: 'success',
      });
      this.form.videoContentUrl = res.data.url;
      this.$refs.form.validateField("videoContentUrl");
    },
    handleRemove() {
      this.form.videoContentUrl = "";
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-container {
  .video-input {
    position: relative;
    .video-text {
      position: absolute;
      right: 5px;
      top: 0px;
      z-index: 1000;
      color: #999999;
    }
  }
  .upload-btn {
    width: 78px;
    height: 28px;
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #439bff;
    color: #439bff;
    text-align: center;
    line-height: 28px;
  }
  .suggested-text {
    font-size: 14px;
    color: #999999;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
