<template>
  <div class="fitness">
    <div class="search-container">
      <el-row>
        <el-col :span="24" style="text-align: right">
          <el-button @click="changeaddDialog(true)" class="search-button"
            >上传</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="videoImgAddress"
          align="center"
          header-align="center"
          min-width="150"
          label="封面"
        >
          <template slot-scope="scope">
            <div class="img-content">
              <img :src="scope.row.videoPictureUrl" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="videoName"
          align="center"
          header-align="center"
          min-width="165"
          label="视频名称"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          min-width="120"
          label="上传时间"
        >
        </el-table-column>
        <el-table-column
          prop="sort"
          align="center"
          min-width="115"
          label="显示顺序"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.sort">
              {{ scope.row.sort }}
            </span>
            <span v-else> 0 </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="collectCount"
          align="center"
          min-width="80"
          label="状态"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.publishStatus === '1'" style="color: #4da2ff">
              已上架
            </span>
            <span class="err" v-else> 已下架 </span>
          </template>
        </el-table-column>
        <el-table-column prop="top" min-width="120" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="success"
              v-if="scope.row.publishStatus === '0'"
              @click="openDeleteDialog([scope.row.id], 9)"
              >上架</el-button
            >
            <el-button
              type="text"
              class="err"
              v-else
              @click="openDeleteDialog([scope.row.id], 17)"
              >下架</el-button
            >
            <el-button
              type="text"
              class="success"
              v-if="scope.row.top === 'N' && scope.row.publishStatus === '1'"
              @click="openDeleteDialog([scope.row.id], 18)"
              >置顶</el-button
            >
            <el-button
              type="text"
              class="success"
              v-if="scope.row.top === 'Y' && scope.row.publishStatus === '1'"
              @click="openDeleteDialog([scope.row.id], 19)"
              >取消置顶</el-button
            >
            <el-button
              type="text"
              class="success"
              v-if="scope.row.publishStatus === '0'"
              @click="changeaddDialog(true, true, scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              class="err"
              v-if="scope.row.publishStatus === '0'"
              @click="openDeleteDialog(scope.row.id, 1)"
              >{{ $t("delete") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 编辑确认弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      :fileData="addDialog.data"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeaddDialog"
    ></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog
      ref="confirmDialog"
      :Language="Language"
      :show="confirmDialog.visible"
      :type="confirmDialog.type"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
  </div>
</template>
<script>
import IndexClass from "./indexClass";
import mixin from "@/mixins/index";
import apis from "@/apis";
import confirmDialog from "@/components/confirmDialog.vue";
import { mapState } from "vuex";
import addDialog from "./dialog/addDialog.vue";

export default {
  mixins: [mixin],
  components: {
    confirmDialog,
    addDialog,
  },
  data() {
    return {
      form: new IndexClass("form"), // 实例化一个表单的变量
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
      addDialog: {
        visible: false,
        isEdit: false,
        data: {},
      },
      tableData: [],
    };
  },
  mounted() {},
  activated() {},
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", "venueId"]),
  },
  methods: {
    // 清空按钮
    // 查询
    searchData() {
      const formData = {
        venueId: this.venueId,
      };
      this.$http.post(apis.venueVideo, formData).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.form.total = res.data.total;
        }
      });
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.id = "";
        this.searchEvent();
      }
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 9:发布
     */
    /* eslint-disable */
    openDeleteDialog(id, type) {
      this.confirmDialog.type = type;
      this.confirmDialog.data.id = id;
      this.changeConfirmDialog(true, type);
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      if (this.confirmDialog.type === 1) {
        this.modelDelete();
      } else if (
        this.confirmDialog.type === 9 ||
        this.confirmDialog.type === 17
      ) {
        this.modelHangOn();
      } else if (
        this.confirmDialog.type === 18 ||
        this.confirmDialog.type === 19
      ) {
        this.modelTop();
      }
    },
    /**
     * 删除
     */
    modelDelete() {
      this.$http
        .delete(
          `${apis.deleteVenueVideo}?id=${this.confirmDialog.data.id}`
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog();
            this.$message.success("删除成功!");
            this.searchData();
          }
        });
    },
    // 上下架
    modelHangOn() {
      let publishStatus = "";
      if (this.confirmDialog.type === 9) {
        publishStatus = 1;
      } else {
        publishStatus = 0;
      }
      this.$http
        .get(
          `${apis.changePublishStatus}?id=${this.confirmDialog.data.id}&publishStatus=${publishStatus}`
        )
        .then((res) => {
          if (res.data.code === 0) {
            if (this.confirmDialog.type === 9) {
              this.$message.success("发布成功!");
            } else {
              this.$message.success("下架成功!");
            }
            this.$refs.confirmDialog.closeDialog();
            this.searchData();
          }
        });
    },
    /**
     * 置顶
     */
    modelTop() {
      let top = "";
      if (this.confirmDialog.type === 18) {
        top = "Y";
      } else {
        top = "N";
      }
      this.$http
        .get(
          `${apis.changeTop}?id=${this.confirmDialog.data.id}&top=${top}`
        )
        .then((res) => {
          if (res.data.code === 0) {
            if (this.confirmDialog.type === 18) {
              this.$message.success("置顶成功!");
            } else {
              this.$message.success("取消置顶成功!");
            }
            this.$refs.confirmDialog.closeDialog();
            this.searchData();
          }
        });
    },
    changeaddDialog(dialogStatus, isEdit = false, data = {}) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          this.addDialog.data = data;
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs["addDialog"].getDataQuery(data);
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.fitness {
  .el-tabs__active-bar {
    display: none;
  }
  .success {
    color: #6dd400;
  }
  .err {
    color: #e02020;
  }
}
.img-content {
  vertical-align: middle;
  text-align: center;
  img {
    width: 75px;
    height: 45px;
  }
}
</style>

